@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background-color: #0121ff;
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  10% {
    opacity: 1;
    transform: translateY(0);
  }
  90% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.animate-fade-in-out {
  animation: fade-in-out 5s ease-in-out forwards;
}


